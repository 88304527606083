<template>
    <card header-classes="border-0" bodyClasses="p-0 mt-1">
        <template slot="header">
          <b-row class="align-items-center">
            <b-col sm="8">
              <vue-json-to-csv :json-data="analyticsData"
              :labels="csvData"
              class="d-flex justify-content-left align-items-center download-btn" csv-title="AnalyticsData"
              >
              <el-tooltip effect="dark" placement="bottom-start" content="Download">
                  <div class="font-weight-bold">Download &nbsp;</div>
              </el-tooltip>
              <i class="ni ni-download"></i>
              </vue-json-to-csv>
            </b-col>
            <b-col sm="4">
              <!-- <analytics-date v-model="date"/> -->
              <div class="d-flex">
                <date-picker placeholder="Select date"
                  class="analytic-date-picker" format="DD MMM YYYY" v-model="selectedDates"
                  :disabled-date="(date) => date >= new Date()" range>
                </date-picker>
                <button class="btn btn-primary ml-2" :disabled="!isDateSelected" @click="getAnalytics()">
                  Update
                </button>
              </div>
            </b-col>
          </b-row>
        </template>
        <div>
          <el-table
              ref="analyticTable1"
              class="table-responsive table-flush"
              header-row-class-name="thead-light"
              empty-text="Waiting for your team to start texting"
              :data="teamTotal"
              :cell-style="{height: '58px'}"
              :default-sort="{ prop: 'opt', order: 'ascending' }">
          <el-table-column label="" prop="outs">
              <template></template>
          </el-table-column>
          <el-table-column label="response rate" prop="conversion">
              <template></template>
          </el-table-column>
          <el-table-column label="texts sent" prop="sendData">
              <template></template>
          </el-table-column>
          <el-table-column label="texts received" prop="receiveData">
              <template></template>
          </el-table-column>
          <el-table-column label="new conversations" prop="newConversation">
              <template></template>
          </el-table-column>
          <el-table-column label="opt outs" prop="opt">
              <template></template>
          </el-table-column>
          </el-table>
          <el-table
          ref="analyticTable"
          class="table-responsive table-flush mb-4"
          header-row-class-name="thead-light"
          empty-text="Waiting for your team to start texting"
          :data="analyticsData"
          :cell-style="{height: '58px'}"
          :default-sort="{ prop: 'opt', order: 'ascending' }">
            <el-table-column label="name" prop="name">
                <template></template>
            </el-table-column>
            <el-table-column label="response rate" prop="conversion" sortable>
              <template v-slot="{ row }">
                {{ row.conversion > 0 ? row.conversion : 0 }} %
              </template>
            </el-table-column>
            <el-table-column label="texts sent" prop="send" sortable>
                <template v-slot="{ row }">
                {{ row.send > 0 ? row.send : 0 }}
                </template>
            </el-table-column>
            <el-table-column label="texts received" prop="received" sortable>
                <template v-slot="{ row }">
                {{ row.received > 0 ? row.received : 0 }}
                </template>
            </el-table-column>
            <el-table-column label="new conversations" prop="newConversation" sortable>
              <template v-slot="{ row }">
                {{ row.newConversation > 0 ? row.newConversation : 0 }}
              </template>
            </el-table-column>
            <el-table-column label="opt outs" prop="opt" sortable>
              <template v-slot="{ row }">
                {{ row.opt > 0 ? row.opt : 0 }}
              </template>
            </el-table-column>
          </el-table>
        </div>
    </card>
</template>
<script>
import {Table, TableColumn} from 'element-ui';
import moment from 'moment';
import {APP_DATE_FORMAT} from '@/util/config.js';
// import AnalyticsDate from '../account/analytics/AnalyticsDate.vue';
import {GET_ACCOUNT_ANALYTICS} from '@/store/storeActions';
import {mapActions, mapGetters} from 'vuex';
import VueJsonToCsv from 'vue-json-to-csv';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    // AnalyticsDate,
    DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueJsonToCsv,
  },
  props: {
    seats: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    date: moment().format(APP_DATE_FORMAT),
    selectedDates: [new Date(), new Date()],
    isFirstTime: true,
    teamTotal: [{
      outs: 'Team totals',
      conversion: '-',
      newConversation: '-',
      sendData: '-',
      receiveData: '-',
      opt: '-',
    },
    {
      outs: 'Average',
      conversion: '-',
      newConversation: '-',
      sendData: '-',
      receiveData: '-',
      opt: '-',
    }],
    csvData: {
      name: {title: 'Name'},
      conversion: {title: 'Response Rate'},
      send: {title: 'Text Sent'},
      received: {title: 'Text Received'},
      opt: {title: 'OPT'},
    },
    isDateSelected: false,
  }),
  computed: {
    ...mapGetters([
      'accountTextAnalytics',
    ]),
    analyticsData() {
      return this.seats.map((seat) => {
        return this.getMemberTextAnalytics(seat);
      });
    },
  },
  watch: {
    // date: {
    //   handler: function(newVal) {
    //     this.getAccountTextAnalytics(newVal);
    //   },
    //   immediate: true,
    // },
    selectedDates: {
      handler: function(value) {
        this.selectedDates = value;
        this.isDateSelected = value && value[0] !== null ? true : false;
        if (this.isDateSelected && this.isFirstTime) {
          this.getAnalytics();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      GET_ACCOUNT_ANALYTICS,
    ]),
    getAccountTextAnalytics: function(date, endDate) {
      this.GET_ACCOUNT_ANALYTICS({date, endDate});
    },
    getMemberTextAnalytics(seat) {
      if (this.accountTextAnalytics.length === 0) {
        return {};
      }
      const send = this.accountTextAnalytics.totalSent[seat.userId] ?
      this.accountTextAnalytics.totalSent[seat.userId] : 0;
      const received = this.accountTextAnalytics.totalReceived[seat.userId] ?
          this.accountTextAnalytics.totalReceived[seat.userId] : 0;
      return {
        send: send,
        received: received,
        name: [seat.user.firstName, seat.user.lastName].filter(Boolean).join(' ').trim(),
        conversion: received > 0 && send > 0 ? Math.round((received / send) * 100) : 0,
        newConversation: this.accountTextAnalytics.newConversations[seat.userId] ?
          this.accountTextAnalytics.newConversations[seat.userId] : 0,
        opt: this.accountTextAnalytics.newOptouts[seat.userId] ?
          this.accountTextAnalytics.newOptouts[seat.userId] : 0,
      };
    },
    getTotalSent() {
      return this.analyticsData.reduce((acc, item) => acc + item.send, 0);
    },
    getTotalReceive() {
      return this.analyticsData.reduce((acc, item) => acc + item.received, 0);
    },
    getTotalNewConversations() {
      return this.analyticsData.reduce((acc, item) => acc + item.newConversation, 0);
    },
    getTotalOptouts() {
      return this.analyticsData.reduce((acc, item) => acc + item.opt, 0);
    },
    getTeamTotal() {
      this.teamTotal[0].sendData = this.getTotalSent();
      this.teamTotal[0].receiveData = this.getTotalReceive();
      const conversionTotal = Math.round((this.getTotalReceive() / this.getTotalSent()) * 100);
      this.teamTotal[0].conversion = (!isNaN(conversionTotal) ? conversionTotal : 0) + ' %';
      this.teamTotal[0].newConversation = this.getTotalNewConversations();
      this.teamTotal[0].opt = this.getTotalOptouts();
      this.teamTotal[1].sendData = this.analyticsData.length > 0 ?
        (this.teamTotal[0].sendData / this.analyticsData.length).toFixed(2) : 0;
      this.teamTotal[1].receiveData = this.analyticsData.length > 0 ?
        (this.teamTotal[0].receiveData / this.analyticsData.length).toFixed(2) : 0;
      const conversionAverage = Math.round((this.getTotalReceive() / this.getTotalSent()) * 100);
      this.teamTotal[1].conversion = this.analyticsData.length > 0 ?
       (!isNaN(conversionAverage) ? conversionAverage / this.analyticsData.length : 0) + ' %' : 0;
      this.teamTotal[1].newConversation = this.analyticsData.length > 0 ?
        (this.teamTotal[0].newConversation / this.analyticsData.length).toFixed(2) : 0;
      this.teamTotal[1].opt = this.analyticsData.length > 0 ?
        (this.teamTotal[0].opt / this.analyticsData.length).toFixed(2) : 0;
    },
    getAnalytics() {
      this.isFirstTime = false;
      if (this.isDateSelected) {
        const dates = [];
        this.selectedDates.forEach((item) => {
          dates.push(moment(item).format('YYYY-MM-DD'));
        });
        const startDate = dates[0];
        const endDate = dates[1];
        this.getAccountTextAnalytics(startDate, endDate);
      }
    },
  },
  updated() {
    this.getTeamTotal();
  },
};
</script>
<style lang="scss">
  .download-btn {
    width: fit-content;
    cursor: pointer;
  }
  .analytic-date-picker input{
    height: 42px;
  }
  .mx-calendar-panel-date:last-child{
    display: none;
  }
</style>
